import React, { useState, useEffect } from 'react';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 20);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const navItems = [
    { name: 'Home', href: '#home' },
    { name: 'AI Therapist', href: '#aitherapist' },
    { name: 'Benefits', href: '#benefits' },
    { name: 'Tokenomics', href: '#tokenomics' },
    { name: 'Roadmap', href: '#roadmap' },
    { name: 'Contact', href: '#contact' }
  ];

  return (
    <nav className={`fixed w-full z-50 transition-all duration-300 ${
      scrolled 
     ? 'bg-black/40 backdrop-blur-lg border-b border-gray-600' 
        : 'bg-transparent py-4'
    }`}>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between w-full">
          {/* Logo with more pronounced hover effect */}
          <div className="flex items-center ml-0">
            <a href="#" className="flex items-center space-x-2 group">
              <div className="relative">
                <img src="/DegenTherapistLogo.png" alt="Degen Therapist Logo" className="relative h-24 object-contain" />
              </div>
            </a>
          </div>

          {/* Desktop Navigation with more refined styling */}
          <div className="hidden md:flex items-center space-x-8">
            {navItems.map((item) => (
              <a 
                key={item.name} 
                href={item.href} 
                className="relative group"
              >
                <span className="relative z-10 text-gray-300 group-hover:text-white transition-colors duration-300 font-medium text-sm uppercase tracking-wider">
                  {item.name}
                </span>
                <span className="absolute bottom-0 left-0 w-0 h-0.5 bg-gradient-to-r from-[#00f5ff] to-[#7928ca] group-hover:w-full transition-all duration-300"></span>
              </a>
            ))}
            
            <button className="px-6 py-2 bg-gradient-to-r from-[#00f5ff] to-[#7928ca] text-gray-300 rounded-full text-sm font-bold uppercase tracking-wider transform hover:scale-105 transition-all duration-300 hover:shadow-lg">
              Buy on Pump.fun
            </button>
          </div>

          {/* Mobile Menu Button with more dynamic animation */}
          <div className="md:hidden">
            <button 
              onClick={() => setIsOpen(!isOpen)}
              className="relative w-10 h-10 focus:outline-none text-gray-300"
            >
              <div className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
                <span className={`block w-6 h-0.5 bg-current transform transition-all duration-300 ${
                  isOpen? 'rotate-45 translate-y-1.5' : '-translate-y-1.5'
                }`}></span>
                <span className={`block w-6 h-0.5 bg-current transform transition-all duration-300 my-1 ${
                  isOpen? 'opacity-0' : 'opacity-100'
                }`}></span>
                <span className={`block w-6 h-0.5 bg-current transform transition-all duration-300 ${
                  isOpen? '-rotate-45 -translate-y-1.5' : 'translate-y-1.5'
                }`}></span>
              </div>
            </button>
          </div>
        </div>

        {/* Mobile Menu with improved design */}
        <div className={`md:hidden transition-all duration-300 ease-in-out ${
          isOpen? 'max-h-96 opacity-100' : 'max-h-0 opacity-0'
        } overflow-hidden`}>
          <div className="bg-black/60 backdrop-blur-lg rounded-xl mt-4 p-6 space-y-4 border border-gray-600 shadow-2xl">
            {navItems.map((item) => (
              <a 
                key={item.name} 
                href={item.href} 
                className="block text-center text-gray-300 hover:text-white py-3 rounded-lg transition-colors duration-300 hover:bg-gray-600/20"
                onClick={() => setIsOpen(false)}
              >
                {item.name}
              </a>
            ))}
            <button 
              onClick={() => window.open('https://pump.fun', '_blank')}
              className="w-full py-3 bg-gradient-to-r from-[#00f5ff] to-[#7928ca] text-gray-300 rounded-full text-sm font-bold uppercase tracking-wider transform hover:scale-105 transition-all duration-300"
            >
              Buy on Pump.fun
            </button>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
