import React, { useEffect, useRef } from 'react'
import { FaTelegram, FaTwitter } from 'react-icons/fa'

function Footer() {
  const footerRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('visible');
          }
        });
      },
      { threshold: 0.1 }
    );

    if (footerRef.current) {
      observer.observe(footerRef.current);
    }

    return () => {
      if (footerRef.current) {
        observer.unobserve(footerRef.current);
      }
    };
  }, []);

  const socialLinks = [
    { 
      name: 'Twitter', 
      url: 'https://twitter.com/DegenTherapistAI', 
      icon: <FaTwitter /> 
    },
    { 
      name: 'Telegram', 
      url: 'https://t.me/soltherapistportal', 
      icon: <FaTelegram /> 
    }
  ]

  const quickLinks = [
    { name: 'Buy $DTAI', url: 'https://x.com/dtaionsol', external: true },
    { name: 'Tokenomics', url: '#tokenomics' },
    { name: 'Benefits', url: '#benefits' }
  ]

  return (
    <footer
      ref={footerRef}
      className="relative py-32 bg-[#0a0a1a] overflow-hidden"
      id="contact"
    >
      {/* Dynamic Background Gradient Blurs Removed */}
      <div className="absolute inset-0 pointer-events-none overflow-hidden">
        {/* Color pop circles removed */}
      </div>

      <div className="container mx-auto px-4 max-w-7xl relative z-10">
        <div className="grid md:grid-cols-3 gap-12 text-center">
          {/* Project Info */}
          <div className="glassmorphic-container p-8 rounded-2xl backdrop-blur-lg bg-white/5">
            <h3 className="text-3xl font-bold mb-6 text-transparent bg-clip-text bg-gradient-to-r from-[#00f5ff] to-[#7928ca]">
              $DTAI
            </h3>
            <p className="mb-6 text-white/80">
              Where crypto meets comedy. AI-powered sarcastic therapy for traders navigating the wild crypto landscape.
            </p>
            <div className="flex space-x-6 justify-center">
              {socialLinks.map((link, index) => (
                <a 
                  key={index} 
                  href={link.url} 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="text-3xl text-white/80 hover:text-transparent hover:bg-clip-text hover:bg-gradient-to-r hover:from-[#00f5ff] hover:to-[#7928ca] transition-all duration-300 transform hover:scale-110"
                >
                  {link.icon}
                </a>
              ))}
            </div>
          </div>

          {/* Quick Links */}
          <div className="glassmorphic-container p-8 rounded-2xl backdrop-blur-lg bg-white/5">
            <h4 className="text-2xl font-semibold mb-6 text-transparent bg-clip-text bg-gradient-to-r from-[#00f5ff] to-[#7928ca]">
              Quick Links
            </h4>
            <ul className="space-y-4">
              {quickLinks.map((link, index) => (
                <li key={index}>
                  <a 
                    href={link.url} 
                    target={link.external? '_blank' : '_self'}
                    rel={link.external? 'noopener noreferrer' : ''}
                    className="text-white/80 hover:text-transparent hover:bg-clip-text hover:bg-gradient-to-r hover:from-[#00f5ff] hover:to-[#7928ca] transition-colors"
                  >
                    {link.name}
                  </a>
                </li>
              ))}
            </ul>
          </div>

          {/* Community */}
          <div className="glassmorphic-container p-8 rounded-2xl backdrop-blur-lg bg-white/5">
            <h4 className="text-2xl font-semibold mb-6 text-transparent bg-clip-text bg-gradient-to-r from-[#00f5ff] to-[#7928ca]">
              Join the Madness
            </h4>
            <p className="text-white/80 mb-6">
              Get your daily dose of crypto comedy and AI-powered therapy
            </p>
            <a 
              href="https://t.me/soltherapistportal" 
              target="_blank" 
              rel="noopener noreferrer"
              className="inline-block btn-futuristic px-6 py-3 rounded-full bg-gradient-to-r from-[#00f5ff] to-[#7928ca] text-white font-bold transform transition-all duration-300 hover:scale-105 hover:rotate-1 hover:shadow-2xl"
            >
              Join Telegram Community
            </a>
          </div>
        </div>

        {/* Copyright */}
        <div className="text-center mt-16 pt-8 border-t border-white/10">
          <p className="text-sm text-white/50">
            {new Date().getFullYear()} Degen Therapist AI. No financial advice, just laughs.
          </p>
          <p className="text-xs text-white/50 mt-4">
            Please be advised that $DTAi is for entertainment and community engagement purposes only. Cryptocurrencies, including $DTAi, are high-risk investments subject to significant market volatility and regulatory uncertainties. Participation in the $DTAi project should be considered speculative, and potential investors should thoroughly understand these risks before participating. Always consult a financial advisor before making any investment decisions.
          </p>
        </div>
      </div>
    </footer>
  )
}

export default Footer;
