import React from 'react';

function Roadmap() {
  return (
    <section
      id="roadmap"
      className="relative min-h-screen flex flex-col items-center justify-center bg-[#0a0a1a] overflow-hidden py-12"
    >
      {/* Background Gradient Blurs */}
      <div className="absolute inset-0 pointer-events-none overflow-hidden">
        <div className="absolute top-[10%] left-[5%] w-64 h-64 bg-[#00f5ff] rounded-full opacity-10 animate-random-bounce blur-3xl"></div>
        <div className="absolute bottom-[20%] right-[10%] w-80 h-80 bg-[#7928ca] rounded-full opacity-10 animate-random-bounce blur-3xl"></div>
      </div>

      <h1 className="z-10 text-center text-4xl md:text-6xl font-bold mb-12 leading-tight text-transparent bg-clip-text bg-gradient-to-r from-[#00f5ff] via-[#7928ca] to-[#ff0080] animate-gradient-x">
        Roadmap
      </h1>

      <div className="relative z-10 w-full px-4 max-w-7xl mx-auto">
        <div className="glassmorphic-container backdrop-blur-xl bg-opacity-30 rounded-3xl p-8 md:p-12 shadow-2xl border border-opacity-10 border-white/20 transform transition-all duration-300 hover:scale-[1.02]">
          <div className="flex flex-col lg:flex-row justify-center">
            <div className="phase md:w-1/2 bg-gradient-to-br from-[#1a1a2e] to-[#16213e] p-4 mb-8 lg:mb-4 lg:mr-4 rounded-xl shadow-lg transform transition-all duration-300 hover:scale-105 hover:shadow-2xl">
              <h2 className="text-2xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-[#00f5ff] to-[#7928ca] mb-2 text-center">Prelaunch:</h2>
              <ul className="text-center">
                <li className="text-lg text-white/80 mb-2">Develop Telegram and X Bot to establish a strong online presence and engage with the community.</li>
                <li className="text-lg text-white/80 mb-2">Create a comprehensive Go-To-Market Strategy to effectively reach and acquire new users.</li>
                <li className="text-lg text-white/80 mb-2">Design and launch the Website and other essential Assets to showcase the project's vision and features.</li>
              </ul>
            </div>
            <div className="phase md:w-1/2 bg-gradient-to-br from-[#1a1a2e] to-[#16213e] p-4 mb-8 lg:mb-4 lg:mx-4 rounded-xl shadow-lg transform transition-all duration-300 hover:scale-105 hover:shadow-2xl">
              <h2 className="text-2xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-[#00f5ff] to-[#7928ca] mb-2 text-center">Launch:</h2>
              <ul className="text-center">
                <li className="text-lg text-white/80 mb-2">Successfully launch PumpFun to introduce the project to the public and generate initial interest.</li>
                <li className="text-lg text-white/80 mb-2">Announce the Utility of the project to highlight its unique value proposition and attract users.</li>
                <li className="text-lg text-white/80 mb-2">Find a solid floor to establish a stable foundation for the project's growth and development.</li>
              </ul>
            </div>
            <div className="phase md:w-1/2 bg-gradient-to-br from-[#1a1a2e] to-[#16213e] p-4 mb-8 lg:mb-4 lg:ml-4 rounded-xl shadow-lg transform transition-all duration-300 hover:scale-105 hover:shadow-2xl">
              <h2 className="text-2xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-[#00f5ff] to-[#7928ca] mb-2 text-center">Growth:</h2>
              <ul className="text-center">
                <li className="text-lg text-white/80 mb-2">Increase the marketing budget to expand the project's reach and attract new users.</li>
                <li className="text-lg text-white/80 mb-2">Host Community competitions to foster engagement, encourage participation, and build a loyal community.</li>
                <li className="text-lg text-white/80 mb-2">Announce strategic Partnerships to collaborate with other projects and expand the project's ecosystem.</li>
              </ul>
            </div>
            <div className="phase md:w-1/2 bg-gradient-to-br from-[#1a1a2e] to-[#16213e] p-4 mb-8 lg:mb-4 lg:ml-4 rounded-xl shadow-lg transform transition-all duration-300 hover:scale-105 hover:shadow-2xl">
              <h2 className="text-2xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-[#00f5ff] to-[#7928ca] mb-2 text-center">Scale:</h2>
              <ul className="text-center">
                <li className="text-lg text-white/80 mb-2">Scale marketing ads to reach a wider audience and increase brand awareness.</li>
                <li className="text-lg text-white/80 mb-2">Collaborate with Social Media KOLs to leverage their influence and promote the project.</li>
                <li className="text-lg text-white/80 mb-2">To Be Announced (more to come) - future plans and developments will be shared in due course.</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Roadmap;
