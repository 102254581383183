import React from 'react';
import Navbar from './components/Navbar';
import Hero from './components/Hero';
import Features from './components/Features';
import Tokenomics from './components/Tokenomics';
import Roadmap from './components/Roadmap';
import Footer from './components/Footer';
import AITherapist from './components/AITherapist';

function App() {
  return (
    <div className="bg-[#0a0a1a] min-h-screen">
      <Navbar />
      <Hero />
      <AITherapist />
      <Features />
      <Tokenomics />
      <Roadmap />
      <Footer />
    </div>
  );
}

export default App;
