import React from 'react';
import { FaRobot, FaCoins, FaUsers, FaLaugh } from 'react-icons/fa';

const BenefitCard = ({ icon, title, description, index }) => (
  <div 
    className="glassmorphic-container relative group overflow-hidden transform transition-all duration-300 hover:scale-[1.05]"
    style={{ animationDelay: `${index * 150}ms` }}
  >
    <div className="p-8 relative z-10 text-center">
      {/* Floating Icon Container */}
      <div className="relative w-20 h-20 mb-6 mx-auto">
        <div className="absolute inset-0 bg-gradient-to-r from-[#00f5ff] to-[#7928ca] opacity-20 blur-lg rounded-full animate-pulse"></div>
        <div className="relative z-10 flex items-center justify-center w-full h-full text-5xl transform transition-all duration-300 hover:scale-110">
          <span className="transition-all duration-300 hover:drop-shadow-lg hover:text-shadow-md">{icon}</span>
        </div>
      </div>

      <h3 className="text-2xl font-bold mb-4 text-transparent bg-clip-text bg-gradient-to-r from-[#00f5ff] to-[#7928ca]">
        {title}
      </h3>
      <p className="text-white leading-relaxed">
        {description}
      </p>
    </div>
  </div>
);

const Benefits = () => {
  const benefits = [
    {
      icon: <><FaRobot /> </>,
      title: 'Degen Therapist AI',
      description: 'Unleash the power of satirical AI that provides hilariously sarcastic advice tailored for crypto traders.'
    },
    {
      icon: <><FaCoins /> </>,
      title: 'Zero Tax Trading',
      description: '0% buy and sell taxes. Maximum flexibility for traders without hidden fees or complicated tokenomics.'
    },
    {
      icon: <><FaUsers /> </>,
      title: 'Community-Owned',
      description: '100% of tokens in the liquidity pool. No reserved tokens, no dev wallet - pure community ownership.'
    },
    {
      icon: <><FaLaugh /> </>,
      title: 'Crypto Comedy',
      description: 'Transform your trading stress into laughter with AI-generated humor that understands the crypto rollercoaster.'
    }
  ];

  return (
    <section
      id="benefits"
      className="relative py-32 bg-gray-900 overflow-hidden"
    >
      {/* Background Gradient Blurs */}
      <div className="absolute inset-0 pointer-events-none overflow-hidden">
        <div className="absolute top-[10%] left-[5%] w-64 h-64 bg-[#00f5ff] rounded-full opacity-10 animate-random-bounce blur-3xl"></div>
        <div className="absolute bottom-[20%] right-[10%] w-80 h-80 bg-[#7928ca] rounded-full opacity-10 animate-random-bounce blur-3xl"></div>
      </div>

      <div className="container mx-auto px-4 max-w-7xl relative z-10">
        <div className="text-center mb-20">
          <h2 className="text-4xl md:text-5xl font-bold mb-6 text-transparent bg-clip-text bg-gradient-to-r from-[#00f5ff] to-[#7928ca] animate-gradient-x">
            $DTAI Benefits
          </h2>
          
          <p className="text-lg md:text-xl text-white/80 max-w-3xl mx-auto leading-relaxed">
            Where crypto meets comedy.
            <span className="text-transparent bg-clip-text bg-gradient-to-r from-[#00f5ff] to-[#7928ca] font-bold"> Transparent. Fair. Hilarious. </span>
            A token that turns trading into an entertaining experience.
          </p>
        </div>

        {/* Responsive Benefits Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {benefits.map((benefit, index) => (
            <BenefitCard
              key={index}
              icon={benefit.icon}
              title={benefit.title}
              description={benefit.description}
              index={index}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Benefits;
