import React from 'react'
import { FaCoins, FaLock, FaUsers } from 'react-icons/fa'

function Tokenomics() {
  return (
    <section
      id="tokenomics"
      className="relative py-32 bg-[#0a0a1a] overflow-hidden"
    >
      {/* Background Gradient Blurs */}
      <div className="absolute inset-0 pointer-events-none overflow-hidden">
        <div className="absolute top-[10%] left-[5%] w-64 h-64 bg-[#00f5ff] rounded-full opacity-10 animate-random-bounce blur-3xl"></div>
        <div className="absolute bottom-[20%] right-[10%] w-80 h-80 bg-[#7928ca] rounded-full opacity-10 animate-random-bounce blur-3xl"></div>
      </div>

      <div className="container mx-auto px-4 max-w-7xl relative z-10">
        <div className="text-center mb-16">
          <h2 className="text-4xl md:text-5xl font-bold mb-6 text-transparent bg-clip-text bg-gradient-to-r from-[#00f5ff] to-[#7928ca] animate-gradient-x">
            $DTAI Tokenomics
          </h2>
          <p className="text-lg md:text-xl text-white/80 max-w-3xl mx-auto leading-relaxed">
            Transparent. Fair. No hidden agendas.
            <span className="text-transparent bg-clip-text bg-gradient-to-r from-[#00f5ff] to-[#7928ca] font-bold"> 100% Community-Owned </span>
            with zero tax friction.
          </p>
        </div>

        {/* Main Content - Image and Tokenomics */}
        <div className="flex flex-col md:flex-row gap-12 items-center">
          {/* Left Side - Shibadoge Image */}
          <div className="w-full md:w-3/5 flex justify-center">
            <img 
              src="/shibadoge.png" 
              alt="Shibadoge Mascot" 
              className="w-full max-w-2xl rounded-3xl transform transition-all duration-300 hover:scale-105"
            />
          </div>

          {/* Right Side - Tokenomics Boxes */}
          <div className="w-full md:w-2/5 flex flex-col gap-4">
            {[
              {
                icon: <FaCoins />,
                title: 'Total Supply',
                details: [
                  { label: 'Total Tokens', value: '1,000,000,000 $DTAI' },
                  { label: 'Blockchain', value: 'Solana' },
                  { label: 'Token Type', value: 'Meme/Utility' }
                ]
              },
              {
                icon: <FaLock />,
                title: 'Liquidity & Taxes',
                details: [
                  { label: 'Liquidity Pool', value: '100% of Tokens' },
                  { label: 'Buy Tax', value: '0%' },
                  { label: 'Sell Tax', value: '0%' }
                ]
              },
              {
                icon: <FaUsers />,
                title: 'Community Ownership',
                details: [
                  { label: 'Dev Wallet', value: 'None' },
                  { label: 'Reserved Tokens', value: 'None' },
                  { label: 'Fair Launch', value: 'Guaranteed' }
                ]
              }
            ].map((section, index) => (
              <div 
                key={index} 
                className={`glassmorphic-container transform transition-all duration-300 hover:scale-[1.02] overflow-hidden 
                  ${index === 0? 'border-2 border-white/20 p-1' : ''}
                  ${index === 1? 'border-2 border-white/20 p-1' : ''}
                  ${index === 2? 'border-2 border-white/20 p-1' : ''}
                `}
              >
                <div className="p-4 text-center relative z-10">
                  <div className="text-4xl mb-4 text-center flex justify-center text-transparent bg-clip-text bg-gradient-to-r from-[#00f5ff] to-[#7928ca]">
                    {section.icon}
                  </div>
                  <h3 className="text-xl font-bold mb-4 text-transparent bg-clip-text bg-gradient-to-r from-[#00f5ff] to-[#7928ca]">
                    {section.title}
                  </h3>
                  <ul className="space-y-2">
                    {section.details.map((detail, detailIndex) => (
                      <li 
                        key={detailIndex} 
                        className="flex justify-between border-b border-white/10 pb-2 last:border-b-0"
                      >
                        <span className="text-white/80 text-sm">{detail.label}:</span>
                        <span className="font-bold text-white text-sm">{detail.value}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Integrated Token Utility Section */}
        <div className="mt-16">
          <div className="text-center mb-12">
            <h3 className="text-3xl mb-4 text-transparent bg-clip-text bg-gradient-to-r from-[#00f5ff] to-[#7928ca]">
              Token Utility & Ecosystem
            </h3>
            <p className="text-white/80 max-w-3xl mx-auto">
              $DTAI is more than just a token. It's a comprehensive ecosystem designed to provide value, entertainment, and innovative AI-powered tools for the crypto community.
            </p>
          </div>
          
          <div className="grid md:grid-cols-3 gap-8">
            {[
              { 
                title: 'Degen Therapist AI', 
                description: 'Access hilarious, AI-generated crypto trading advice and mental health commentary',
                icon: '🤖'
              },
              { 
                title: 'Community Memes', 
                description: 'Generate and share AI-powered memes about crypto trading experiences',
                icon: '😂'
              },
              { 
                title: 'Satirical Insights', 
                description: 'Receive sarcastic market analysis and trading psychology insights',
                icon: '🔍'
              },
              { 
                title: 'VIP Alpha Group', 
                description: 'Meet like-minded crypto enthusiasts and gain access to exclusive tools',
                icon: '🚀'
              },
              { 
                title: 'AI Crypto Analysis', 
                description: 'Get AI-powered insights on the latest projects and trade like a PRO',
                icon: '📊'
              },
              { 
                title: 'Team Meetings', 
                description: 'Join weekly team meetings to understand our innovative AI development',
                icon: '👥'
              }
            ].map((utility, index) => (
              <div 
                key={index} 
                className="glassmorphic-container transform transition-all duration-300 hover:scale-[1.05] bg-white/10 rounded-3xl p-6 border border-white/10"
              >
                <div className="text-center">
                  <div className="text-4xl mb-4">{utility.icon}</div>
                  <h4 className="text-xl mb-4 text-black text-transparent bg-clip-text bg-gradient-to-r from-[#00f5ff] to-[#7928ca] font-bold">
                    {utility.title}
                  </h4>
                  <p className="text-white/80 text-sm">{utility.description}</p>
                </div>
              </div>
            ))}
          </div>
          
          <div className="mt-12 text-center">
            <p className="text-white/70 max-w-4xl mx-auto text-sm">
              By holding $DTAI, you're not just investing in a token, but participating in a revolutionary AI-driven crypto ecosystem that combines humor, insights, and cutting-edge technology.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Tokenomics;
