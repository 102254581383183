import React, { useState, useEffect } from 'react';
import heroImage from '/iso-hero.png';

const Hero = () => {
  const [countdown, setCountdown] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0
  });

  const launchDate = new Date('2024-12-17T18:00:00+00:00').getTime();

  useEffect(() => {
    const timer = setInterval(() => {
      const now = new Date().getTime();
      const distance = launchDate - now;

      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      setCountdown({ days, hours, minutes, seconds });
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  return (
    <section
      id="home"
      className="relative min-h-screen flex items-center justify-center overflow-hidden bg-[#0a0a1a] pt-28"
    >
      {/* Animated Color Pops */}
      <div className="absolute inset-0 pointer-events-none overflow-hidden">
        <div className="absolute top-[10%] left-[5%] w-64 h-64 bg-[#00f5ff] rounded-full opacity-15 animate-random-bounce blur-3xl"></div>
        <div className="absolute bottom-[20%] right-[10%] w-80 h-80 bg-[#7928ca] rounded-full opacity-15 animate-random-bounce blur-3xl"></div>
        <div className="absolute top-[50%] left-[50%] w-96 h-96 bg-[#ff0080] rounded-full opacity-15 animate-random-bounce blur-3xl"></div>
      </div>

      <div className="relative z-10 w-full px-4 max-w-6xl mx-auto">
        <div className="grid md:grid-cols-2 gap-24 items-center justify-between">
          {/* Left Column - Content */}
          <div className="text-left md:pr-12">
            <h1 className="text-4xl md:text-6xl font-bold mb-6 leading-tight text-transparent bg-clip-text bg-gradient-to-r from-[#00f5ff] via-[#7928ca] to-[#ff0080] animate-gradient-x">
              $DTAI: Your Own Degen AI Therapist
            </h1>
            
            <p className="text-xl md:text-2xl text-white/80 mb-10 max-w-3xl leading-relaxed">
              AI Trading Therapist meets zero-tax trading on Solana.
              Join our Telegram now for early-access.
            </p>

            {/* Launch Countdown */}
            <div className="mb-10">
              <h2 className="text-2xl font-bold text-white/90 mb-6 tracking-wider">
                Launching in:
              </h2>
              <div className="flex space-x-4 md:space-x-6">
                {['days', 'hours', 'minutes', 'seconds'].map((unit) => (
                  <div
                    key={unit}
                    className="bg-gradient-to-br from-[#1a1a2e] to-[#16213e] p-4 md:p-6 rounded-xl shadow-lg transform transition-all duration-300 hover:scale-105 hover:shadow-2xl"
                  >
                    <span className="text-3xl md:text-4xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-[#00f5ff] to-[#7928ca]">
                      {countdown[unit]}
                    </span>
                    <p className="text-xs md:text-sm text-white/70 uppercase tracking-wider mt-1">
                      {unit}
                    </p>
                  </div>
                ))}
              </div>
            </div>
            
            {/* Action Buttons */}
            <div className="flex flex-col sm:flex-row items-start space-y-4 sm:space-y-0 sm:space-x-6">
              <button
                className="btn-futuristic w-48 py-3 rounded-full bg-gradient-to-r from-[#00f5ff] to-[#7928ca] text-white font-bold transform transition-all duration-300 hover:scale-105 hover:rotate-1 hover:shadow-2xl"
                onClick={() => window.open('https://x.com/dtaionsol', '_blank')}
              >
                Buy $DTAI
              </button>
              
              <button
                className="btn-futuristic w-48 py-3 rounded-full bg-gradient-to-r from-[#1a1a2e] to-[#16213e] text-white font-bold border-2 border-[#00f5ff] transform transition-all duration-300 hover:scale-105 hover:rotate-1 hover:shadow-2xl"
                onClick={() => window.open('https://t.me/soltherapistportal', '_blank')}
              >
                Join Community
              </button>
            </div>
          </div>

          {/* Right Column - Hero Image */}
          <div className="flex justify-center items-center">
            <img
              src={heroImage}
              alt="Degen Therapist Hero"
              className="max-w-full h-auto transform transition-all duration-300 hover:scale-105"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
